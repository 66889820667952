import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {IResponseProps, ISite} from "../type";

export const APP_KEY = "batches";

interface IColumnName {
    id: string;
    batchNumber: string;
    containerId: string;
    createdAt: string;
    createdDateTime: string;
    createdBy: string;
    totalMass: string;
    remainingMass: number;
    status: string;
    isTrusted: boolean;
    address: string;
    site: ISite;
    comments?: string;
}

interface State {
    batch: IColumnName[];
    error: string;
    addBatchLoading: boolean;
    batchLoading: boolean;
    showBatchDetailsFlag: boolean;
    response: IResponseProps;
}

const initialState: State = {
    batch: [],
    error: '',
    addBatchLoading: true,
    batchLoading: true,
    showBatchDetailsFlag: false,
    response: {
        data: undefined,
        status: undefined,
        statusText: undefined
    }
};

const AppSlice = createSlice({
    name: APP_KEY,
    initialState,
    reducers: {
        setBatchLoading(state: State) {
            state.batchLoading = true;
        },
        setAddBatchLoading(state: State) {
            state.addBatchLoading = true;
        },
        setBatch(state: State, action: PayloadAction<IColumnName[]>) {
            state.batch = action.payload;
            state.batchLoading = false;
        },
        setShowBatchDetailsFlag(state:State, action: PayloadAction<boolean>) {
            state.showBatchDetailsFlag = action.payload;
        },
        clearBatch(state: State, action: PayloadAction<string>) {
            state.batch = [];
            state.batchLoading = false;
            state.error = action.payload;
        }
    },
});

export const {setBatchLoading, setBatch, clearBatch,setShowBatchDetailsFlag} = AppSlice.actions;

const selectBatchSlice = (state: RootState) => state.batches;

export const batchSelector = createSelector(selectBatchSlice, (state) => ({
    batch: state.batch,
    response: state.response,
    batchLoading: state.batchLoading,
    showBatchDetailsFlag: state.showBatchDetailsFlag,
    error: state.error
}));

export const batchReducer = AppSlice.reducer;

