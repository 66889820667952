export const HEADER_NAME ="Batch";

export const field_name = {
    BATCH_NUMBER:'Batch ID',
    TRAILER_NAME:"Trailer Name",
    CREATION_DATE:"Creation Date",
    CREATED_BY:"Created By",
    SITE_NAME:"Site Name",
    AMOUNT:"Amount",
    REMAINING:"Remaining",
    STATUS:'Status',
    TAGS:'Tags'
}

export const field_id = {
    batchId:'batchId',
    trailerName:"trailerName",
    creationDate:"creationDate",
    createdBy:"createdBy",
    siteName:"siteName",
    amount:"amount",
    remainingMass:"remainingMass",
    status:'status',
    tags:'tags',
    action:'action'
}

export const TRAILER_STATUS ={
    IN_ACTIVE : 'InActive'
}

export const CREATED_BY = {
    SYSTEM:'SYSTEM',
    USER:'USER'
}